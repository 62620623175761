<template>
	<div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
			<DataTable :value="$store.state.risk.detail.filter(val => val.del == false)" :scrollable="true" :scrollHeight="heigt_sc+'px'"  v-model:selection="selectedCustomers" 
				:filters="filters" class="p-datatable-sm" :paginator="false" editMode="cell" dataKey="id"  
				@cell-edit-init="Init" @cell-edit-complete="Save" v-model:editingRows="editingRows" v-model:expandedRows="expandedRows">
				<template #header>
					<div class="table-header">
						<div>
							<strong>{{$t('Responsible')}}</strong>
							<InputText v-model="$store.state.risk.data.responsible" style="width: 100%;"/>	
						</div>
						<div>
							<Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('New')" @click="New"/>
						</div>
					</div>
				</template>
				<Column :header="$t('N°')" bodyStyle="width: 30px; background-color: #CECECE; text-align: center;" headerStyle="width: 30px; background-color: #CECECE; text-align: center;">
					<template #body="slotProps" >
						{{slotProps.index+1}}
					</template>
					<template #editor="slotProps">
						{{slotProps.index+1}}
					</template>
				</Column>
				<Column field="status" :header="$t('Status')" headerStyle="width: 130px">
					<template #body="{data}">
						{{ data['status_'+i18n.locale()] }}<i class="pi pi-pencil edit_tab" v-if="data.status_id==1"/>
					</template>
					<template #editor="{ data }">
						<div v-if="data.status_id==1">
							<div v-for="opc in $store.state.risk.basic.status.filter(val => val.id<3)" :key="opc.key" class="p-ml-1 p-mr-1 p-mb-1">
								<RadioButton v-model="data.status" :inputId="opc.key" name="dynamic" :value="opc"/>
								<label :for="opc.key" class="p-ml-2 p-mr-2">{{ opc[i18n.locale()] }}</label>
							</div>
						</div>
						<div v-else>
							{{ data['status_'+i18n.locale()] }}
						</div>
					</template>
				</Column>
                <Column field="description" :header="$t('Scenario')" headerStyle="width: 300px">
					<template #body="{data}">
						{{ data.description }}<i class="pi pi-pencil edit_tab" v-if="data.status_id==1"/>
					</template>
					<template #editor="{ data }">
						<div v-if="data.status_id==1">
							<Textarea v-model="data.description" autoResize rows="3" style="width: 100%;"/>
						</div>
						<div v-else>
							{{ data.description }}
						</div>
					</template>
				</Column>
                <Column field="categorization" :header="$t('Categorization of business relationships')" headerStyle="width: 200px" >
					<template #body="{data}">
						{{ data['categorization_'+i18n.locale()] }}<i class="pi pi-pencil edit_tab" v-if="data.status_id==1"/>
					</template>
					<template #editor="{ data }">
						<div v-if="data.status_id==1">
							<div v-for="opc in $store.state.risk.basic.categorization" :key="opc.key" class="p-ml-1 p-mr-1 p-mb-1">
								<RadioButton v-model="data.categorization" :inputId="opc.key" name="dynamic" :value="opc"/>
								<label :for="opc.key" class="p-ml-2 p-mr-2">{{ opc[i18n.locale()] }}</label>
							</div>
						</div>
                        <div v-else>
							{{ data['categorization_'+i18n.locale()] }}
						</div>
					</template>
				</Column>
                <Column field="risk_provider" :header="$t('Which risk is the provider likely to Face?')" headerStyle="width: 200px">
					<template #body="{data}">
						{{ data['risk_provider_'+i18n.locale()] }}<i class="pi pi-pencil edit_tab" v-if="data.status_id==1"/>
					</template>
					<template #editor="{ data }">
						<div v-if="data.status_id==1">
							<div v-for="opc in $store.state.risk.basic.provider" :key="opc.key" class="p-ml-1 p-mr-1 p-mb-1">
								<RadioButton v-model="data.risk_provider" :inputId="opc.key" name="dynamic" :value="opc"/>
								<label :for="opc.key" class="p-ml-2 p-mr-2">{{ opc[i18n.locale()] }}</label>
							</div>
						</div>
                        <div v-else>
							{{ data['risk_provider_'+i18n.locale()] }}
						</div>
					</template>
				</Column>
                <Column field="factor" :header="$t('Risk Factors')" headerStyle="width: 200px">
					<template #body="{data}">
						<div v-for="opc of data.factor" :key="opc.id" class="p-ml-1 p-mr-1 p-mb-1">
							{{ opc[i18n.locale()] }}
						</div>
                        <i class="pi pi-pencil edit_tab" v-if="data.status_id==1"/>
					</template>
					<template #editor="{ data }">
						<div v-if="data.status_id==1">
							<div v-for="category of $store.state.risk.basic.factor" :key="category.key" class="p-ml-1 p-mr-1 p-mb-1">
								<Checkbox v-model="data.factor"  :value="category"/>
								<label :for="category.key">{{ category[i18n.locale()] }}</label>
							</div>
						</div>
                        <div v-else>
							<div v-for="opc of data.factor" :key="opc.id" class="p-ml-1 p-mr-1 p-mb-1">
								{{ opc[i18n.locale()] }}
							</div>
						</div>
					</template>
				</Column>
                <Column field="del" :header="$t('Delete')"  headerStyle="width: 40px">
					<template #body="{data}">
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-secondary" @click="data.del = true" v-tooltip.bottom="$t('Delete')" v-if="data.status_id==1"/>
					</template>
					<template #editor="{ data }">
						<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary" @click="data.del = true" v-tooltip.bottom="$t('Delete')" v-if="data.status_id==1"/>
					</template>
				</Column>
			</DataTable>
        </div>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";

	export default {
		props: {
		},
		data() {
			return {
				i18n: null,
				url: null,
				img: null,
				porc: 0,
				heigt_sc: 0,
				editingRows: [],
				expandedRows: [],
				product: null,
				error:null,
			}
		},
		created() {
			this.i18n = useI18nPlugin();
			this.url = process.env.VUE_APP_API;
			const tokgen = new Token(256);
			this.img = tokgen.generate();
			if(this.$store.state.screen<=728){
				this.heigt_sc =  380;
			} else {
				this.heigt_sc =  500;
			}
		},
		mounted() {
			
		},
		methods: {
			New() {
				this.$store.state.risk.detail.push({
                    id: 0,
                    risk: this.$store.state.risk.data.id,
                    del: false,
                    categorization: this.$store.state.risk.basic.categorization[0].id,
                    categorization_en: this.$store.state.risk.basic.categorization[0].en,
                    categorization_es: this.$store.state.risk.basic.categorization[0].es,
                    risk_provider: this.$store.state.risk.basic.provider[0].id,
                    risk_provider_en: this.$store.state.risk.basic.provider[0].en,
                    risk_provider_es: this.$store.state.risk.basic.provider[0].es,
                    description: '',
                    factor: [],
                    likelihood: this.$store.state.risk.basic.likelihood[0].id,
					impact: this.$store.state.risk.basic.impact[0].id,
					appetite: this.$store.state.risk.basic.appetite[0].id,
					responsible: '',
					mitigating_measures: '',
					mitigating: this.$store.state.risk.basic.mitigating[0].id,
					issues: '',
					date_start: null,
					date_end: null,
					mitigating_desc: '',
					gaps: '',
					measures: '',
					status_id: 1,
					status: this.$store.state.risk.basic.status[0].id,
					status_en: this.$store.state.risk.basic.status[0].en,
					status_es: this.$store.state.risk.basic.status[0].es,
                });
            },
			Init(event) {
				let { data, index, field } = event;
				this.$store.state.risk.editing.push(field+index);
				switch (field) {
					case 'status':
						this.$store.state.risk.detail[index][field] = this.$store.state.risk.basic.status.filter(val => val.id == data.status)[0];
						break;
					case 'categorization':
						this.$store.state.risk.detail[index][field] = this.$store.state.risk.basic.categorization.filter(val => val.id == data.categorization)[0];
						break;
					case 'risk_provider':
						this.$store.state.risk.detail[index][field] = this.$store.state.risk.basic.provider.filter(val => val.id == data.risk_provider)[0];
						break;
				}
			},
			Save(event) {
				let { data, index, field } = event;
				this.$store.state.risk.editing = this.$store.state.risk.editing.filter(val => val != field+index);
				switch (field) {
					case 'status':
						this.$store.state.risk.detail[index].status_en = data.status.en;
						this.$store.state.risk.detail[index].status_es = data.status.es;
						this.$store.state.risk.detail[index].status = data.status.id;
						break;
					case 'categorization':
						this.$store.state.risk.detail[index].categorization_en = data.categorization.en;
						this.$store.state.risk.detail[index].categorization_es = data.categorization.es;
						this.$store.state.risk.detail[index].categorization = data.categorization.id;
						break;
					case 'risk_provider':
						this.$store.state.risk.detail[index].risk_provider_en = data.risk_provider.en;
						this.$store.state.risk.detail[index].risk_provider_es = data.risk_provider.es;
						this.$store.state.risk.detail[index].risk_provider = data.risk_provider.id;
						break;
				}
			},
		}
	}
</script>
<style scoped lang="scss">
.cheap {
	background-color: #54a90a !important;
	color: #ffffff !important;
}

.edit_tab{
	padding: 10px 10px 10px 10px;
	font-size: 10px;
	color: red;
}

.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

@media (max-width: 640px) {

	
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}


				}
			}
		}
	}
}
</style>